import React from 'react'
import { connect } from "react-redux"
import {withRouter} from "react-router-dom"


import styles from "./index.less"
import { NAV_CHANGE_TAB } from '@/constant'

const mapStateToProps = (state) => {
	return {
	  selectTab: state.headerTab.selectTab
	}
}

@connect(mapStateToProps)
class Tab extends React.Component {

	changeTab = (selectTab) => {
		const { dispatch, history } = this.props
		dispatch({
			type: NAV_CHANGE_TAB,
			selectTab
		})
		history.push(`/${selectTab}`)
	}

	componentDidMount() {
		const {dispatch, location: { pathname }, selectTab} = this.props

		// 数组形式，不同path相同tab做预留
		const pathArray = [
			{
				router: ['/home'],
				tab: 'home'
			}
		]

		pathArray.forEach(({router, tab}) => {
			if(router.includes(pathname) && selectTab !== tab) {
				dispatch({
					type: NAV_CHANGE_TAB,
					selectTab: tab
				})
			}
		})
	}

	render() {
		const { selectTab } = this.props
		

		const TAB_CONTENT = [
			{
				name: '三方组件列表',
				tab: 'home',
				pathName: 'home'
			}
		]
		return (
			<>
				<div className={styles.contents}>
					{TAB_CONTENT.map(({name, tab, pathName}) => (
						<span key={tab} onClick={() => this.changeTab(pathName)} className={tab === selectTab ? styles.selectTab : null}>{name}</span>
					))}
				</div>
			</>
		)
	}
}

export default withRouter(Tab)