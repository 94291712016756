import React from 'react'
import {connect} from 'react-redux'
import {Spin} from 'antd'

import styles from './index.less'

const mapStateToProps = (state) => {
	return {
        loading: state.loading.loading
	}
}
@connect(mapStateToProps)

class Loading extends React.Component {
    render(){
        const {loading} = this.props
        if(loading) {
            return (
                <div className={styles.loading}>
                    <Spin size="large"/>
                </div>
            )
        }
        return <></>
    }
}

export default Loading