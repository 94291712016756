import { NAV_CHANGE_TAB } from '@/constant'

const headerTab = {
    state: {
        selectTab: 'home' // 默认首页
    },
    reducer: {
        [NAV_CHANGE_TAB](state, action) {
            return {...state, selectTab: action.selectTab}
        }
    }
}

export default headerTab