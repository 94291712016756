import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from "react-router-dom"
import {Form, Input, Button, Col, message} from 'antd'

import { USER_KEY } from '@/constant'
import {modifyPassword} from '@/api'
import styles from './index.less'
const getUserInfo = state => {
    return {
        userInfo: state.home.userInfo,
    }
}


@connect(getUserInfo)
class  ModifyPassword extends React.Component {
    handleSubmit = e => {
        e.preventDefault();
        const {history} = this.props
        this.props.form.validateFields((isVoid, values) => {
            if(values.newPassword !== values.newPasswordAgain) {
                message.error('新密码两次输入不一致，请重新输入')
                return
            }
            if (!isVoid) {
                modifyPassword(values).then(res => {
                    if(res.code === 200) {
                        message.success('密码修改成功', () => {
                            localStorage.removeItem(USER_KEY)
                            history.goBack()
                        })
                    }
                })
            }
        })
    }
    render () {
        const {userInfo = {}} = this.props
        const { getFieldDecorator } = this.props.form;
        const getUserInfo = userInfo.name ? userInfo : JSON.parse(localStorage.getItem('info'))
        const MODIFY_FROM = [
			{
				formKey: "oldPassword",
				message: "原密码不能为空",
				require: true,
				type: "password",
				iconColor: "rgba(0,0,0,.25)",
                placeholder: "请输入原密码",
                label: '输入原密码'
			},
			{
				formKey: "newPassword",
				message: "密码不能为空",
				require: true,
				type: "password",
				iconColor: "rgba(0,0,0,.25)",
                placeholder: "输入新密码",
                label: '输入新密码'
            },
            {
				formKey: "newPasswordAgain",
				message: "密码不能为空",
				require: true,
				type: "password",
				iconColor: "rgba(0,0,0,.25)",
                placeholder: "确认新密码",
                label: '确认新密码'
			}
		]
        return (
            <div className={styles.modifyPassword}>
                <div className={styles.userInfo}>
                    <div className={styles.title}>在线升级管理平台</div>
                    <div className={styles.info}>
                        <div className={styles.userName}>{getUserInfo.name}</div>
                        <div className={styles.icon}></div>
                        <div className={styles.time}>
                            <div className={styles.timeContent}>
                                <span>升级许可到期时间：</span>
                                <span>{getUserInfo.time}</span>
                            </div>
                            <div className={styles.iconuser}></div>
                        </div>
                    </div>
                    
                </div>
                <div className={styles.modifyContent}>
                    <div className={styles.pageName}>修改密码</div>
                    <Form onSubmit={this.handleSubmit} className="login-form" >
						{MODIFY_FROM.map(form => (
                            <React.Fragment key={form.formKey}>
                                <span className={styles.label}>{form.label}</span>
                                <Form.Item key={form.type} style={{marginBottom: '10px'}}>
                                    {getFieldDecorator(form.formKey, {
                                        rules: [{ required: form.require, message: form.message }]
                                    })(
                                        <Input
                                            placeholder={form.placeholder}
                                            size="large"
                                            type={form.type}
                                        />
                                    )}
                                </Form.Item>
                            </React.Fragment>
							
						))}
						<Form.Item>
                            <Col style={{ textAlign: 'right' }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    确定
                                </Button>
                            </Col>
							
						</Form.Item>
					</Form>
                </div>
            </div>
        )
    }
}


const ModifyForm = Form.create({ name: "modify" })(ModifyPassword);
export default withRouter(ModifyForm)