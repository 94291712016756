import React from 'react'
import {Progress} from 'antd'
import styles from './index.less'

const ProgressTip = (props) => {
    return (
        <div className={styles.tip}>
            <div>正在获取三方组件漏洞库</div>
            <Progress percent={props.percent} className={styles.aaa}/>
        </div>
    )
}

export default ProgressTip