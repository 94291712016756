import axios from "axios";
import {message} from 'antd'

import loading from '@/actions'
// 配置超时时间
// axios.defaults.timeout = 100000;
// 配置请求拦截
axios.interceptors.request.use(
  // 暂无操作
);

// 添加响应拦截器
axios.interceptors.response.use(
  function(response) {
    loading(false)
    if(response.data.code === 401) {
      
      // history.push('./login')
      window.location.replace('/login')
      return Promise.reject('登录过期，请重新登陆');
    } else if(response.data.code !== 200 && response.data.code !== '200' && response.data.code) {
      message.error(response.data.error)
    }
    return response.data;
  },
  (error) => {
    loading(false)
    if(error.response.status === 401) {
      message.error('登录过期，请重新登陆', () => {
        window.location.replace('/login')
      })
    } else {
      error.response.data && error.response.data.msg && message.error(error.response.data.msg)
    }
    return Promise.reject(error);
  }
);
/**
 * get请求
 * @method get
 * @param {url, loading} 请求地址，请求参数，是否需要加载层
 */
var get = function(url, load = true) {
  loading(load)
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}
/**
 * post请求
 * @method post
 * @param {url, data, load, isFormData} 请求地址，请求参数，是否需要加载层, 是否是formData格式, 其他请求配置
 */
var post = function(url, data, load = true, isFormData = false, config={}) {
  loading(load)
  return new Promise((resolve, reject) => {
    // const newData = isFormData ? data : qs.stringify(data)
    axios
      .post(url,data,config)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

var del = function(url, params, load = true) {
  loading(load)
  return new Promise((resolve, reject) => {
    // qs.stringify(data)
    axios
      .delete(url)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

var put = function(url, params, load = true) {
  loading(load)
  return new Promise((resolve, reject) => {
    axios
      .put(url)
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export default { get, post, delete: del, put };