import {LOADING} from '@/constant'
import store from "@/redux/stroe";

const loading = (loading = false) => {
    store.dispatch({
        type: LOADING,
        loading: loading
    })
}

export default loading