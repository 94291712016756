import React from "react";
import { withRouter } from "react-router-dom";
import { Tooltip, Table, message, Button, Modal } from "antd";

import Icon from "@/components/CoolIcon";
import { getLogList, deleteJournalInfo, deleteBatchInfo } from "@/api";
import {XHRLoadLoadFile} from '@/util'
import deleteModal from '@/hot/deleteModal'
import styles from "./index.less";

@deleteModal
class JournalTable extends React.Component {
  state = {
    listData: [],
    total: 0,
    state: 1, // 日志类型
    current: 1,
    tableHeader: [
      { title: "用户信息", dataIndex: "name", key: "name" } // 默认值
		],
		visible: false
  }
  headerArray = [
    {
      title: "用户名",
      dataIndex: "user_name",
      key: "user_name",
      align: "center"
    },
    { title: "操作对象", dataIndex: "object", key: "object", align: "center" },
    { title: "操作类型", dataIndex: "type", key: "type", align: "center" },
    { title: "操作时间", dataIndex: "time", key: "time", align: "center" },
    {
      title: "操作详情",
      dataIndex: "info",
      key: "info",
			width: 250,
			ellipsis: true,
      align: "center",
      render: text => (
        <Tooltip placement="left" title={text}>
          <div>{text}</div>
        </Tooltip>
      )
    },
    {
      title: "操作",
      align: "center",
      render: (text, list) => (
        <div>
          <span
            className={styles.iconHover}
            onClick={() => this.props.handleDelete(list, this.handleDeleteJarInfo)}
          >
            <Icon name="icon7" />
          </span>
        </div>
      )
    }
	]

	handleDeleteJarInfo = (id) => {
		deleteJournalInfo(id).then(res => {
				const {state} = this.state
				if(res.code === 200) {
						message.success('删除成功', 2, this.getLogListInfo({state: state}))
				}
		})
}
	

  getLogListInfo = (data, remake=true) => {
    const {current} = this.state
    getLogList(data).then(res => {
      if (res.code === 200) {
        const listData = res.data && res.data.list && res.data.list.map(res => {
          res.key = res.id
          return res
        })
        this.setState({
          listData: listData,
          current: remake ? 1 : current,
          total: res.data.pagination.total || 0
        });
      }
    });
	}
	deleteBatchInfo = () => {
		const { state } = this.state
		this.setState({
			visible: false
		},() => {
			deleteBatchInfo(state).then(res => {
				if(res.code === 200) {
					message.success('删除成功', 2, this.getLogListInfo({state: state}))
				}
			})
		})
  }
	hideModal = () => {
		this.setState({
			visible: false
		})
	}
	handleExport = () => {
		const { state } = this.state
		XHRLoadLoadFile(`/api/log/export?state=${state}`)
	}
  componentDidMount() {
    const { location } = this.props;
    const stateKey =
      location.pathname === "/backstage/loginJournal"
        ? 2
        : location.pathname === "/backstage/updateJournal"
        ? 3
        : 1;
    const headerArray = [...this.headerArray];
    if (stateKey === 2) {
      headerArray.splice(1, 1, {
        title: "IP",
        dataIndex: "ip",
        key: "ip",
        align: "center"
      });
    }
    if (stateKey === 3) {
      headerArray.unshift({
        title: "公司名",
        dataIndex: "company_name",
        key: "company_name",
        align: "center"
      });
      headerArray.splice(
        2,
        2,
        { title: "IP", dataIndex: "ip", key: "ip", align: "center" },
        {
          title: "操作对象",
          dataIndex: "object",
          key: "object",
          align: "center"
        }
      );
    }
    this.setState({
      state: stateKey,
      tableHeader: [...headerArray]
    });

    this.getLogListInfo({state: stateKey})
  }
  handleChange = pageInfo => {
    const {state} = this.state
    this.setState({
      current: pageInfo.current
    }, () => {
      this.getLogListInfo({state: state, page: pageInfo.current}, false)
    })
    
  }
  render() {
    const { tableHeader, listData, total, visible, current } = this.state;
    return (
      <div className={styles.tableComponent}>
				<div className={styles.userHandle}>
					<Button className={styles.export} onClick={this.handleExport}>导出</Button>
					<Button onClick={() => {this.setState({visible: true})}}>删除</Button>
				</div>
        <Table
          columns={tableHeader}
          dataSource={listData}
          onChange={this.handleChange}
          pagination={{
            total: total,
            current: current
          }}
        />
				<Modal
          title="确认删除操作"
          visible={visible}
          onOk={this.deleteBatchInfo}
          onCancel={this.hideModal}
          okText="确认"
          cancelText="取消"
          >
            <p>是否删除7天前日志？</p>
        </Modal>
      </div>
    );
  }
}

export default withRouter(JournalTable);
