import React from 'react'
import {withRouter} from "react-router-dom"

import {MANAGE_USER_INfO, MANAGE_BLACK_LIST} from '@/constant'
import SecondTab from '@/components/SecondTab'
import ManageUserTable from '@/components/ManageUserTable'
import BlackListTable from '@/components/BlackListTable'
// import styles from './index.less'

const LIST_TAB = [
    {
        name: '用户',
        tab: MANAGE_USER_INfO,
        pathname: MANAGE_USER_INfO
    },
    {
        name: '黑名单',
        tab: MANAGE_BLACK_LIST,
        pathname: MANAGE_BLACK_LIST
    }
]

class Journal extends React.Component {

    render() {
        const { location } = this.props
        const pathname = location.pathname
        return (
            <SecondTab tabData={LIST_TAB}>
                {
                    pathname === MANAGE_USER_INfO && <ManageUserTable />
                }
                {
                    pathname === MANAGE_BLACK_LIST && <BlackListTable />
                }
            </SecondTab>
        )
    }
}

export default withRouter(Journal)