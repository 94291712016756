import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import routeMenu from "./routeMenu";
import Page404 from "@/pages/Error/404";
import Page500 from "@/pages/Error/500";


const not_manage_path = ['/', '/home', '/modifyPassword', '/login']
const Routes = props => {
  // const userPower = JSON.parse(localStorage.getItem(USER_POWER)).manager
  // const noPowerPath = ['/home', '/modifyPassword']
  // console.log(userPower)
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Switch>
        {routeMenu.map(({ layout: Layout, routes }, i) => {
          return routes.map(routesItem => {
            return (
              <Route
                exact
                key={routesItem.path}
                path={routesItem.path}
                render={(props) => {
                  const {location: { pathname }} = props
                  const manager = JSON.parse(localStorage.getItem('userPower'))?.manager
                  if(!manager && !not_manage_path.includes(pathname)) {
                    return <Page404 />
                  }
                  return (
                  <Layout key={i.toString()} {...props}>
                    <routesItem.component />
                  </Layout>
                )}}
              />
            );
          });
        })}
        <Route exact path="/500" component={Page500} />
        <Route component={Page404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
