import React from 'react'
import { connect } from 'react-redux'
import { Upload, Button, Icon, Pagination, message } from 'antd'

import { USER_INFO, HOME_LIST_INFO } from '@/constant'
import { getHomeInfo, getHomeList, getProgress } from '@/api'

import UserTable from '@/components/UserTable'
import ProgressTip from '@/components/ProgressTip'
import styles from './index.less'
const mapStateToProps = state => {
    return {
        userInfo: state.home.userInfo,
        listInfo: state.home.listInfo
    }
  }

@connect(mapStateToProps)
class Home extends React.Component {
    state = {
        percent: 0,
        showProgress: false
    }
    uploadPro = (info) => {
        if(info.file.status === 'done') {
            if(info.file.response.code !== 200) {
                if(info.file.response.code === 401) {
                    message.error(info.file.response.error, 2, () => {
                        window.location.replace('/login')
                    })
                } else {
                    message.error(info.file.response.error)
                }
                
                return
            }
            this.getProgress()
        }
    }

    getHomeListInfo = (page = 1) => {
        const { dispatch } = this.props
        getHomeList({page: page}).then(res => {
            dispatch({
                type: HOME_LIST_INFO,
                listInfo: res.data
            })
        })
    }

    getProgress = () => {
        getProgress().then(res => {
            if(res.code === 200 && res.data === 10) {
                    this.setState({
                        percent: res.data * 10
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                showProgress: false
                            })
                            this.getHomeListInfo()
                        }, 1000)
                    })
            } else if(res.code === 200 && res.data !== 10) {

                this.setState({
                    showProgress: true,
                    percent: res.data * 10
                })
                // 递归，轮训获取进度
                setTimeout(this.getProgress, 1000)
            }
        })
    }

    pageOnChange = (page) => {
        this.getHomeListInfo(page)
    }



    componentDidMount() {
        const { dispatch } = this.props
        getHomeInfo().then(res => {
            localStorage.setItem('info', JSON.stringify(res.data))
            dispatch({
                type: USER_INFO,
                userInfo: res.data
            })
        })
        // this.getHomeListInfo()
        this.getProgress()
    }

    render() {
        const {listInfo} = this.props
        const {showProgress, percent} = this.state
        const total = (listInfo.pagination && listInfo.pagination.total) || 0
        return (
            <div className={styles.content}>
                <div className={styles.Upload}>
                    <div className={styles.pageName}>三方组件列表</div>
                    <Upload
                        name='file'
                        accept='.json'
                        action='/api/home/upload'
                        withCredentials={true}
                        showUploadList={false}
                        onChange={this.uploadPro}
                    >
                        <Button className={styles.uploadBtn}>
                            <Icon type="upload" /> 上传三方组件文件
                        </Button>
                    </Upload>
                </div>
                { showProgress && <ProgressTip percent={percent}/>}
                <UserTable
                    tableInfo = {listInfo}
                    getHomeListInfo = {this.getHomeListInfo}
                />
                {
                    total ? <Pagination total={total} className={styles.pagination} onChange={this.pageOnChange}/> : null
                }
            </div>
        )
    }
}

export default Home