import { USER_MANAGER } from '@/constant'

const login = {
    state: {
        manager: false // 默认非管理员
    },
    reducer: {
        [USER_MANAGER](state, action) {
            return {...state, manager: action.manager}
        }
    }
}

export default login