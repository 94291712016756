// 基本常量 ==========

// 存储本地key值
export const USER_KEY = 'userInfo'

// 用户权限
export const USER_POWER = 'userPower'

// path 名
export const MANAGE_BLACK_LIST = '/manage/blacklist'
export const MANAGE_USER_INfO = '/manage/userInfo'
export const BACKSTAGE_HANDLE = '/backstage/handleJournal'
export const BACKSTAGE_LOGIN = '/backstage/loginJournal'
export const BACKSTAGE_UPDATE = '/backstage/updateJournal'



// reducer对应的key =====================

// loading是否展示
export const LOADING = 'loading'

// 导航栏点击转换
export const NAV_CHANGE_TAB = 'nav-change-tab'

// 保存用户权限等级

export　const USER_MANAGER = 'user-manager'

// 用户信息
export　const USER_INFO = 'user-info'

// 用户信息
export　const HOME_LIST_INFO = 'home-list-info'