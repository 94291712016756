import React from 'react'
import {Modal} from 'antd'

const confirmModal = (WarpComponent) => {
    return class extends React.Component {
        state = {
            visible: false,
            modalInfo: {},
            deleteFun: null
        }


        handleDelete = (modalInfo, deleteFun) => {
            this.setState({
                visible: true,
                modalInfo: modalInfo || {},
                deleteFun: deleteFun
            })
        }

        hideModal = () => {
            this.setState({
                visible: false
            })
        }
        okModal = () => {
            const {deleteFun, modalInfo} = this.state
            this.setState({
                visible: false
            }, () => {
                deleteFun && deleteFun(modalInfo.id)
            })
        }
        render() {
            const {modalInfo, visible} = this.state
            return (
                <>
                    <WarpComponent {...this.props} handleDelete={this.handleDelete}/>
                    <Modal
                        title="确认删除操作"
                        visible={visible}
                        onOk={this.okModal}
                        onCancel={this.hideModal}
                        okText="确认"
                        cancelText="取消"
                        >
                            <p>是否删除 {modalInfo.name || ''}？</p>
                    </Modal>
                </>
            )
        }
    }
}

export default confirmModal