import React from 'react'
import {Tooltip, message, Empty} from 'antd'
import Icon from '@/components/CoolIcon'
import deleteModal from '@/hot/deleteModal'
import {deleteInfo} from '@/api'
import { XHRLoadLoadFile } from '@/util'
import styles from './index.less'

@deleteModal
class UserTable extends React.Component {

    handleDeleteInfo = (id) => {
        const {getHomeListInfo} = this.props
        deleteInfo(id).then(res => {
            if(res.code === 200) {
                // this.handleGetInfo()
                getHomeListInfo()
            }
        })
    }
    handleDownLoad = (info) => {
        if(!info.down_file_name) {
            message.error('正在获取三方组件漏洞库，请等待')
            return
        }
        XHRLoadLoadFile(`/api/home/download?id=${info.id}`)
    }

    render() {
        const {tableInfo: {list = []}} = this.props
        return (
            <div className={styles.userTable}>
                {
                    list.length > 0
                        ? <>
                            <div className={styles.tableHeader}>
                                <div className={styles.listName}>文件名</div>
                                <div className={styles.listTime}>上传时间</div>
                            </div>
                            <div className={styles.tableBody}>
                                {
                                list.map(info => (
                                    <div className={styles.bodyList} key={info.id}>
                                            <div className={styles.listName}>{info.name}</div>
                                            <div className={`${styles.listTime} ${styles.listTd}`}>
                                                <span>{info.time}</span>
                                                <div className={styles.handle}>
                                                    <Tooltip placement='bottom' title={info.down_file_name ? '下载三方组件库漏洞' : '正在获取三方组件漏洞库，请等待'}>
                                                        <span onClick={() => this.handleDownLoad(info)}><Icon name='xiazai'/></span>
                                                    </Tooltip>
                                                    <span onClick={() => this.props.handleDelete(info, this.handleDeleteInfo)}><Icon name='icon7'/></span>
                                                </div>
                                            </div>
                                    </div>
                                ))
                                }
                            </div>
                        </>
                        : <Empty style={{marginTop: '30px'}} description='暂无数据'/>
                }
            </div> 
        )
    }
}

export default UserTable