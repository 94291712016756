import { USER_INFO, HOME_LIST_INFO } from '@/constant'

const home = {
    state: {
        userInfo: {},
        listInfo: []
    },
    reducer: {
        [USER_INFO](state, action) {
            return {...state, userInfo: action.userInfo}
        },
        [HOME_LIST_INFO](state, action) {
            const cloneData = JSON.parse(JSON.stringify(action.listInfo))
            return {...state, listInfo: cloneData}
        }
    }
}

export default home