import React from 'react'
import {connect} from 'react-redux'
import styles from './index.less'

const mapStateToProps = state => {
    return {
        userInfo: state.home.userInfo
    }
  }

@connect(mapStateToProps)
class Footer extends React.Component {
    render() {
        const { userInfo = {} } = this.props
        return (
            <div className={styles.footer}>
                升级许可到期时间：{userInfo.time}
            </div>
        )
    }
}

export default Footer