import { createStore, combineReducers, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import {reduxUtil, sagaUtil} from "@/util/reduxUtil";
import loading from './Reducer/loading'
import login from './Reducer/login'
import home from './Reducer/home'
import headerTab from './Reducer/header-tab'

const allModals = {
  loading,
  headerTab,
  login,
  home
};

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers(reduxUtil(allModals));

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(sagaUtil(allModals));

export default store;
