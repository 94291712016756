import React from 'react'
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import { Icon, Menu, Dropdown } from 'antd';

import {
	NAV_CHANGE_TAB,
	USER_KEY,
	MANAGE_BLACK_LIST,
	MANAGE_USER_INfO,
	BACKSTAGE_HANDLE,
	BACKSTAGE_LOGIN,
	BACKSTAGE_UPDATE
} from '@/constant'
import {logout, getHomeInfo} from '@/api'
import deleteModal from '@/hot/deleteModal'
import LoginIcon from '@/assets/custom-icon'
import styles from "./index.less";

const mapStateToProps = (state) => {
	return {
	  selectTab: state.headerTab.selectTab,
	  userInfo: state.home.userInfo
	}
}

@connect(mapStateToProps)
@deleteModal
class Tab extends React.Component {

	state = {
		userInfo: {}
	}
	// 退出登录部分操作相关
	handleClick = ({key}) => {
		if(key === 'loginOut') {
			const {history} = this.props
			logout().then(res => {
				if(res.code === 200) {
					localStorage.removeItem(USER_KEY)
					history.replace('/login')
				}
			})
		}
	}

	changeTab = (selectTab) => {
		const { dispatch, history } = this.props
		dispatch({
			type: NAV_CHANGE_TAB,
			selectTab
		})
		history.push(selectTab)
	}
	changePassWord = () => {
		const {history} = this.props
		history.push('/modifyPassword')
	}

	menu = () => (
		<Menu onClick={this.handleClick}>
			<Menu.Item key="changePassWord" onClick={this.changePassWord}>修改密码</Menu.Item>
			<Menu.Item key="loginOut" onClick={this.handleClick}>退出登录</Menu.Item>
		</Menu>	
	)

	componentDidMount() {
		const {dispatch, location: { pathname }, selectTab} = this.props

		getHomeInfo().then(res => {
            localStorage.setItem('info', JSON.stringify(res.data))
            this.setState({
				userInfo: res.data
			})
        })

		// 数组形式，不同path相同tab做预留
		const pathArray = [
			{
				router: [BACKSTAGE_HANDLE, BACKSTAGE_LOGIN, BACKSTAGE_UPDATE],
				tab: 'backstage'
			},
			{
				router: [MANAGE_BLACK_LIST, MANAGE_USER_INfO],
				tab: 'manage'
			}
		]

		pathArray.forEach(({router, tab}) => {
			if(router.includes(pathname) && selectTab !== tab) {
				dispatch({
					type: NAV_CHANGE_TAB,
					selectTab: tab
				})
			}
		})
	}

	render() {
		const { selectTab } = this.props
		const { userInfo } = this.state
		const name=`ant-dropdown-link ${styles.user}`

		const TAB_CONTENT = [
			{
				name: '日志管理',
				tab: 'backstage',
				pathName: BACKSTAGE_HANDLE
			},
			{
				name: '用户管理',
				tab: 'manage',
				pathName: MANAGE_USER_INfO
			}
		]
		return (
			<div className={styles.headerTab}>
				<LoginIcon className={styles.headerIcon}/>
				<div className={styles.contents}>
					{TAB_CONTENT.map(({name, tab, pathName}) => (
						<span key={tab} onClick={() => this.changeTab(pathName)} className={tab === selectTab ? styles.selectTab : null}>{name}</span>
					))}
				</div>
				<Dropdown overlay={this.menu()}>
					<span className={name} href="##">
						<Icon type='user' />
						{userInfo.name}
					</span>
				</Dropdown>,
			</div>
		)
	}
}

export default withRouter(Tab)