import React from 'react'
import {Result} from 'antd'

const Page500 = () => {
    return (
        <Result
            status="500"
            title="500"
            subTitle="Sorry, the server is wrong."
        />
    )
}

export default Page500;