import React from 'react'
import { connect } from 'react-redux'
import {withRouter} from "react-router-dom"
import {Dropdown, Menu} from 'antd'

import Icon from '@/components/CoolIcon'
import {logout} from '@/api'
import {USER_KEY} from '@/constant'
import styles from "./index.less"

const mapStateToProps = state => {
    return {
        userInfo: state.home.userInfo
    }
  }
@connect(mapStateToProps)
class Header extends React.Component {

    // 退出登录部分操作相关
	handleClick = ({key}) => {
		if(key === 'loginOut') {
			const {history} = this.props
			logout().then(res => {
				if(res.code === 200) {
					localStorage.removeItem(USER_KEY)
					history.replace('/login')
				}
			})
		}
	}

	changePassWord = () => {
		const {history} = this.props
		history.push('/modifyPassword')
	}
    
	menu = () => (
		<Menu>
			<Menu.Item key="changePassWord" onClick={this.changePassWord}>修改密码</Menu.Item>
			<Menu.Item key="loginOut" onClick={this.handleClick}>退出登录</Menu.Item>
		</Menu>	
	)

	handleClickGoHome = () => {
		const {history} = this.props
		const getUserPower = JSON.parse(localStorage.getItem('userPower'))?.manager
		if(getUserPower) {
			history.push('/backstage/handleJournal')
		} else {
			history.push('/home')
		}
		
	}
    render() {
		const {userInfo = {}} = this.props
		const localStorageUserName = JSON.parse(localStorage.getItem('info'))?.name
		const name=`ant-dropdown-link ${styles.user}`
        return (
            <div className={styles.headerTab}>
					<div className={styles.pageName} onClick={this.handleClickGoHome}>
						<div>
							<Icon name='logo' size='20px'/>
						</div>
						<span>在线升级管理平台</span>
					</div>
					<Dropdown overlay={this.menu()}>
						<span className={name}>
							<Icon name='yonghu1' style={{marginRight: '5px'}} size='18px'/>
							{userInfo.name || localStorageUserName}
						</span>
					</Dropdown>
				</div>
        )
    }
}

export default withRouter(Header)