import React from 'react'

const LoginIcon = (props) => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="149.999" height="76.001" viewBox="0 0 149.999 76.001" className={props.className}>
        <g id="logo_" data-name="logo " transform="translate(-885 -240.999)">
          <path id="形状_1" data-name="形状 1" d="M986.537,317l26.861-57H1035l-26.86,57Zm-46.316-19,26.864-57h21.6l-26.865,57ZM885,298l26.865-57h21.6L906.6,298Z" fill="#0a3a8c"/>
          <path id="形状_2" data-name="形状 2" d="M986.417,317l-8.939-19h21.6l9,19Zm-92.545-.1L885,298h76.807l-8.952,19ZM967.141,260h-8.948l8.892-19,.056.113v-.077l58.915-.036L1035,260h-9.6l-58.259.032Zm-46.3,0-8.94-19h21.6l9,19Z" fill="#3862ff"/>
          <path id="形状_3" data-name="形状 3" d="M940.762,282.736H919.126l3.363-7.093h21.635Z" fill="#ff822a"/>
          <path id="形状_3_拷贝" data-name="形状 3 拷贝" d="M996.317,282.736H974.681l3.363-7.093h21.635Z" fill="#3862ff"/>
        </g>
      </svg>
    </div>
  )
}
export default LoginIcon