import React from 'react'
import {withRouter} from "react-router-dom"

import deleteModal from '@/hot/deleteModal'
import styles from './index.less'

@deleteModal
class ComponentList extends React.Component {

    changeTab = (selectTab) => {
        this.props.history.push(selectTab)
    }

    handleDelete = (data, fun) => {
        this.props.handleDelete(data, fun)
    }
    render() {
        const { location, children, tabData } = this.props

        
        return (
            <>
                <div className={styles.listTab}>
                    {
                        tabData.map(({name, tab, pathname}) => (
                            <span
                                key={tab}
                                className={pathname === location.pathname ? styles.selectTab : null}
                                onClick={() => this.changeTab(tab)}
                            >
                                {name}
                            </span>
                        ))
                    }
                </div>
                {children}
            </>
        )
    }
}

export default withRouter(ComponentList)