import { LOADING } from '@/constant'

const loading = {
    state: {
        loading: false // 默认首页
    },
    reducer: {
        [LOADING](state, action) {
            return {...state, loading: action.loading}
        }
    }
}

export default loading