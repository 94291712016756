import downloadFun from 'downloadjs';

/**
 * @description 判断是否是promise
 * @param {Blob} obj 对象
 */
export const isPromise = obj => {
  return (
    !!obj &&
    (typeof obj === "object" || typeof obj === "function") &&
    typeof obj.then === "function"
  );
};

/**
 * @XHRLoadLoadFile 无需表单，实现文件下载
 * @param {String} url
 */

export const XHRLoadLoadFile = (url) =>{
  let xhr = new XMLHttpRequest()
    xhr.open('get',url)
    //如果需要请求头中这是token信息可以在这设置
    xhr.setRequestHeader('Content-Type','application/json;charset=UTF-8')
    xhr.responseType = 'blob'
    xhr.send()
    xhr.onreadystatechange = function(){
      if(xhr.readyState ===4 && xhr.status === 200){
        // const blob = new Blob([xhr.response])
        const disposition = xhr.getResponseHeader("Content-disposition"); //处理流文件
        const re = /filename="(.*)"/gi // 必须确保response headers下content-disposition字段的filename格式正确（filename="文件名.扩展名"），否则导出文件会有问题
        const match = re.exec(disposition)
        downloadFun(xhr.response, decodeURIComponent(match[1]) || '')
      }
    }
}

/**
 * @description 首字母大写
 * @param str
 * @return {string}
 */
export const upperFirstLetter = (str = '') => str.slice(0, 1).toUpperCase() + str.slice(1);

export const  timestampToTime = (timestamp) => {
  let data = new Date(parseInt(timestamp))
  var Y = data.getFullYear() + '/';
  var M = (data.getMonth()+1 < 10 ? '0'+(data.getMonth()+1) : data.getMonth()+1) + '/';
  var D = (data.getDate() < 10 ? '0'+data.getDate() : data.getDate()) + ' ';
  var h = (data.getHours() < 10 ? '0'+data.getHours() : data.getHours()) + ':';
  var m = (data.getMinutes() < 10 ? '0'+data.getMinutes() : data.getMinutes()) + ':';
  var s = (data.getSeconds() < 10 ? '0'+data.getSeconds() : data.getSeconds());

  return Y+M+D+h+m+s;
  
}


export const getToken = () => {
  var strcookie = document.cookie;//获取cookie字符串
  var arrcookie = strcookie.split("; ");//分割
  //遍历匹配
  for ( var i = 0; i < arrcookie.length; i++) {
      var arr = arrcookie[i].split("=");
      if (arr[0] === "token"){
          return arr[1];
      }
  }
  return "";
}