import {MANAGE_BLACK_LIST, MANAGE_USER_INfO, BACKSTAGE_HANDLE, BACKSTAGE_LOGIN, BACKSTAGE_UPDATE} from '@/constant'
import BasicLayout from "../layouts/BasicLayout";
import Login from '@/pages/Login'
import Home from '@/pages/Home'
import  ModifyPassword from '@/pages/ModifyPassword'
import  Management from '@/pages/UserManage'
import Journal from '@/pages/Journal'

const routeMenu = [
  {
    layout: BasicLayout,
    routes: [
      {
        path: '/',
        component: Login
      },
      {
        path: '/login',
        component: Login
      },
      {
        path: '/home',
        component: Home
      },
      {
        path: '/modifyPassword',
        component:  ModifyPassword
      },
      //  以下后台页面相关
      {
        path: MANAGE_USER_INfO,
        component:  Management
      },
      {
        path: MANAGE_BLACK_LIST,
        component:  Management
      },
      {
        path: BACKSTAGE_HANDLE,
        component: Journal
      },
      {
        path: BACKSTAGE_LOGIN,
        component: Journal
      },
      {
        path: BACKSTAGE_UPDATE,
        component: Journal
      }
    ]
  }
];

export default routeMenu;
