import React from "react"
import {Table} from 'antd'

import {getUserList} from '@/api'
import styles from './index.less'

class ManageUserTable extends React.Component {
	state = {
		userData: [],
		total: 0
	}

  columns = [
    {
      title: "用户名",
      dataIndex: "login_name",
      key: "login_name",
      align: "center"
		},
		{
			title: "公司名",
      dataIndex: "company_name",
      key: "company_name",
      align: "center"
		},
		{
			title: "升级许可到期时间",
      dataIndex: "license_time",
      key: "license_time",
      align: "center"
		},
		{
			title: "用户创建时间",
      dataIndex: "create_time",
      key: "create_time",
      align: "center"
		}
	]
	
	handleGetUserList = (pageInfo = {}) => {
		getUserList(pageInfo).then(res => {
			if(res.code === 200) {
				const listData = res.data && res.data.list && res.data.list.map((res, index) => {
					res.key = index
					return res
				  })
				this.setState({
					userData: listData,
					total: res.data.pagination.total || 0
				})
			}
		})
	}

	componentDidMount() {
		this.handleGetUserList()
		
	}
  render() {
		const {userData} = this.state
    return (
			<div className={styles.userTable}>
				<Table columns={this.columns} dataSource={userData}/>
			</div>
		)
  }
}

export default ManageUserTable;
