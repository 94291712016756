import React from "react"
import { withRouter} from "react-router-dom"
import Loading from '@/components/Loading'
import UserTab from '@/components/UserTab'
import Tab from '@/components/Tab'
import UserHeader from '@/components/UserHeader'
import UserFooter from '@/components/Footer'

const BasicLayout = (props) => {
  const { children, location: { pathname } } = props;
  const LOGIN_PAGE_KEY = ['/','/login']
  const NO_CONTENT = ['/modifyPassword']
  const HOME = ['/home']
  if(LOGIN_PAGE_KEY.includes(pathname)) { // 登录页面
    return (
      <div>{children}</div>
    )
  }
  if(NO_CONTENT.includes(pathname)) { // 修改密码
    return (
      <>
      <Loading />
      <UserHeader />
      <div>{children}</div>
    </>
    )
  }
  if(HOME.includes(pathname)) { // 用户 页面相关
    return (
      <>
        <Loading />
        <UserHeader />
        <UserTab />
        <div>{children}</div>
        <UserFooter />
      </>
    )
  }
  return (
    <>
      <Loading />
      <Tab />
      <div>{children}</div>
    </>
  );
};

export default withRouter(BasicLayout);
