import React from 'react'
import { Table, message, Modal, Form, DatePicker, Input, ConfigProvider, Button } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import moment from 'moment';
import 'moment/locale/zh-cn';

import { deleteBlackInfo, getBlackList, addBlackInfo, updateBlackInfo } from '@/api'
import Icon from '@/components/CoolIcon'
import deleteModal from '@/hot/deleteModal'
import styles from './index.less'
moment.locale('zh-cn');

const { RangePicker } = DatePicker
const dateFormat = "YYYY-MM-DD HH:mm:ss"
@deleteModal
class BlackListTable extends React.Component {
	state = {
		listData: [],
		showModal: false,
		modalData: {
			start_time: null,
			end_time: null
		},
		isAdd: true
	}
	columns = [
		{
			title: "IP",
			dataIndex: "ip",
			key: "ip",
			align: "center"
		},
		{
			title: "开始时间",
			dataIndex: "start_time",
			key: "start_time",
			align: "center"
		},
		{
			title: "结束时间",
			dataIndex: "end_time",
			key: "end_time",
			align: "center"
		},
		{
			title: "最后编辑时间",
			dataIndex: "gmt_modififed",
			key: "gmt_modififed",
			align: "center"
		},
		{
			title: "操作",
			align: "center",
			render: (text, list) => (
				<div className={styles.handle}>
					<div onClick={() => this.handleEdit(list)}>
						<Icon name='bianji' />
					</div>
					<div onClick={() => this.props.handleDelete(list, this.handleDeleteInfo)}>
						<Icon name='icon7' />
					</div>
				</div>
			)
		},
	]

	handleGetBlackList = (pageInfo = {}, remake = true) => {
		getBlackList(pageInfo).then(res => {
			const { current } = this.state
			if (res.code === 200) {
				const listData = res.data && res.data.list && res.data.list.map(res => {
					res.key = res.id
					return res
				})
				this.setState({
					listData: listData,
					current: remake ? 1 : current,
					total: res.data.pagination.total || 0
				})
			}
		})
	}

	handleDeleteInfo = (id) => {
		deleteBlackInfo(id).then(res => {
			if (res.code === 200) {
				message.success('删除成功', 2, this.handleGetBlackList)
			}
		})
	}

	handleEdit = (list) => {
		this.setState({
			showModal: true,
			modalData: { ...list },
			isAdd: false
		})
	}

	handleForm = e => {
		e.preventDefault();
		const { isAdd, modalData } = this.state
		this.props.form.validateFields((err, fieldsValue) => {
			if (err) {
				return
			}
			const postData = {
				ip: fieldsValue.ip,
				startTime: fieldsValue.time[0].format(dateFormat),
				endTime: fieldsValue.time[1].format(dateFormat)
			}
			if (!isAdd) {
				postData.id = modalData.id
			}
			const handleFun = isAdd ? addBlackInfo : updateBlackInfo
			const msgText = isAdd ? '添加成功' : '编辑成功'
			handleFun(postData).then(res => {
				if (res.code === 200) {
					this.setState({
						showModal: false
					})
					message.success(msgText, 2, this.handleGetBlackList)
				}
			})

		})
	}

	handleClose = () => {
		this.setState({
			showModal: false,
			modalData: {
				start_time: null,
				end_time: null
			},
			isAdd: true
		})
	}

	handleAdd = () => {
		this.setState({
			showModal: true,
			modalData: {},
			isAdd: true
		})
	}

	handleChange = pageInfo => {
		this.setState({
			current: pageInfo.current
		}, () => {
			this.handleGetBlackList({ page: pageInfo.current }, false)
		})

	}

	componentDidMount() {
		this.handleGetBlackList()
	}
	render() {
		const { showModal, modalData, isAdd, listData, total, current } = this.state
		const { getFieldDecorator } = this.props.form;
		const modalName = isAdd ? '添加黑名单' : '编辑黑名单'
		const timeDefault = isAdd ? [modalData.start_time, modalData.end_time] : [moment(modalData.start_time, dateFormat), moment(modalData.end_time, dateFormat)]
		return (
			<div className={styles.blackTable}>
				<div className={styles.handleAdd}>
					<Icon name='tianjia' size='18px' onClick={this.handleAdd} />
				</div>
				<Table
					columns={this.columns}
					dataSource={listData}
					onChange={this.handleChange}
					pagination={{
						total: total,
						current: current
					}}
				/>
				{
					// showModal 不以变量形式纯如modal的visible，防止initialValue值不随state变化而变化
					showModal && <Modal
						title={modalName}
						visible={true}
						footer={null}
						onCancel={this.handleClose}
					>
						<div className={styles.sec}>加入黑名单的IP不能在访问客户服务平台</div>
						{/* ConfigProvider 全局设置语言转换， RangePicker 默认为英文 */}
						<ConfigProvider locale={zh_CN}>
							<Form onSubmit={this.handleForm}>
								<Form.Item label="IP" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
									{
										getFieldDecorator('ip', {
											rules: [{ required: true, message: 'ip必须填写' }],
											initialValue: modalData.ip
										})(
											<Input placeholder="请输入ip" disabled={!isAdd} />
										)
									}
								</Form.Item>
								<Form.Item label="期限选择" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
									{
										getFieldDecorator('time', {
											rules: [{ type: 'array', required: true, message: '请选择时间' }],
											initialValue: timeDefault
										})(
											<RangePicker
												showTime
												format={dateFormat}
												style={{ width: '100%' }}
											/>,
										)
									}
								</Form.Item>
								<Form.Item>
									<div className={styles.btn}>
										<Button onClick={this.handleClose}>
											取消
										</Button>
										<Button type="primary" htmlType="submit" style={{ marginLeft: '20px' }}>
											确认
										</Button>
									</div>

								</Form.Item>
							</Form>
						</ConfigProvider>
					</Modal>
				}
			</div>
		)
	}
}


const WrappedForm = Form.create({ name: 'black-list-form' })(BlackListTable);
export default WrappedForm