import React from 'react'
import {withRouter} from "react-router-dom"

import {BACKSTAGE_HANDLE, BACKSTAGE_LOGIN, BACKSTAGE_UPDATE} from '@/constant'
import SecondTab from '@/components/SecondTab'
import JournalTable from '@/components/JournalTable'
// import styles from './index.less'

const LIST_TAB = [
    {
        name: '用户操作日志',
        tab: BACKSTAGE_HANDLE,
        pathname: BACKSTAGE_HANDLE
    },
    {
        name: '用户登录日志',
        tab: BACKSTAGE_LOGIN,
        pathname: BACKSTAGE_LOGIN
    },
    {
        name: '在线升级日志',
        tab: BACKSTAGE_UPDATE,
        pathname: BACKSTAGE_UPDATE
    }
]

class Journal extends React.Component {

    render() {
        return (
            <SecondTab tabData={LIST_TAB}>
                <JournalTable />
            </SecondTab>
        )
    }
}

export default withRouter(Journal)