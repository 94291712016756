import React from "react";
import { withRouter } from "react-router-dom";
import {connect} from 'react-redux'

import { login, changeCode } from "@/api";
import { USER_MANAGER, USER_KEY, USER_POWER, BACKSTAGE_HANDLE } from '@/constant'
import { Form, Button, Input, Icon, Checkbox } from "antd";

import styles from "./index.less";
import loginBg from './image/login_bg.png';



@connect()
class Login extends React.Component {
	state = {
		needCode: false,
		codeImg: "",
		userStatus: false // 是否记住密码
	};
	handleSubmit = e => {
		e.preventDefault();
		const {userStatus} = this.state
		this.props.form.validateFields((isVoid, values) => {
			if (!isVoid) {
				
				login(values).then(res => {
					if (res.code === 200) {
						if(userStatus) {
							const userInfo = {
								userName: values.userName,
								password: values.password,
								userStatus: userStatus,
							}
							localStorage.setItem(USER_KEY, JSON.stringify(userInfo))
						}
						// dispatch({
						// 	type: USER_MANAGER,
						// 	manager: res.manager
						// })
						localStorage.setItem(USER_POWER, JSON.stringify({manager: res.manager}))
						if(res.manager) {
							this.props.history.push(BACKSTAGE_HANDLE)
						} else {
							this.props.history.push("/home")
						}
					} else if (res.code === 406) {
						this.setState({
							needCode: true,
							codeImg: `data:image/png;base64,${res.data}`
						})
					}
				})
			}
		})
	}

	handleCode = () => {
		const {getFieldValue} = this.props.form
		const userName=getFieldValue('userName')
		const password=getFieldValue('password')
		changeCode({
			userName: userName,
			password: password
		}).then(res => {
			this.setState({
				codeImg: `data:image/png;base64,${res.data}`
			})
		})
	}

	changeStatus = (e) => {
		this.setState({
			userStatus: e.target.checked
		})
	}

	componentWillMount() {
		const {dispatch} = this.props
		const userInfo = JSON.parse(localStorage.getItem(USER_KEY)) || {}
		if(userInfo.userStatus && userInfo.userName && userInfo.password) {
			login(userInfo).then(res => {
				if (res.code === 200) {
					dispatch({
						type: USER_MANAGER,
						manager: res.manager
					})
					localStorage.setItem(USER_POWER, JSON.stringify({manager: res.manager}))
					if(res.manager) {
						this.props.history.push(BACKSTAGE_HANDLE)
					} else {
						this.props.history.push("/home")
					}
				}
			})
		}
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { needCode, codeImg } = this.state;
		const LOGIN_FROM = [
			{
				formKey: "userName",
				message: "用户名不能为空",
				require: true,
				type: "user",
				icon: "user",
				iconColor: "rgba(0,0,0,.25)",
				placeholder: "用户名"
			},
			{
				formKey: "password",
				message: "密码不能为空",
				require: true,
				type: "password",
				icon: "lock",
				iconColor: "rgba(0,0,0,.25)",
				placeholder: "密码"
			}
		]
		return (
			<div className={styles.loginBox}>
				<img src={loginBg} alt=""/>
				<div className={styles.login}>
					<div className={styles.loginInfo}>
						{/* <LoginIcon /> */}
						<div className={styles.platform}>欢迎登录在线升级平台</div>
					</div>
					<Form onSubmit={this.handleSubmit} className="login-form" >
						{LOGIN_FROM.map(form => (
							<Form.Item key={form.type}>
								{getFieldDecorator(form.formKey, {
									rules: [{ required: form.require, message: form.message }]
								})(
									<Input
										prefix={
											<Icon
												type={form.icon}
												style={{ color: form.iconColor }}
											/>
										}
										placeholder={form.placeholder}
										size="large"
										type={form.type}
									/>
								)}
							</Form.Item>
						))}
						{needCode && (
							<Form.Item key="code">
								{getFieldDecorator("code", {
									rules: [{ required: true, message: '验证码不能为空' }]
								})(
									<div className={styles.inputCode}>
										<Input
											prefix={
												<Icon
													type="code"
													style={{ color: "rgba(0,0,0,.25)" }}
												/>
											}
											placeholder="验证码"
											size="large"
											type='code'
										/>
										<span className={styles.code} onClick={this.handleCode}>
											<img
												src={codeImg}
												alt=""
											/>
										</span>
									</div>
								)}
							</Form.Item>
						)}
						<Checkbox onChange={this.changeStatus} className={styles.checked}>自动登录</Checkbox>
						<Form.Item>
							<Button
								type="primary"
								htmlType="submit"
								block={true}
								size="large"
							>
								登录
              				</Button>
						</Form.Item>
					</Form>
				</div>
				{/* <div className={styles.footer}>
					Copyright <Icon type="copyright" /> 杭州孝道科技有限公司
        		</div> */}
			</div>
		);
	}
}

const LoginForm = Form.create({ name: "login" })(Login);

export default withRouter(LoginForm);
