import axios from '@/util/axios'

/**
 * @login 登陆接口
 * @param userInfo 用户信息相关 {username, password}
 **/
export const login = (userInfo) => {
    const defaultInfo = {
        userName: '',
        password: '',
        code: '',
        ...userInfo
    }
    !defaultInfo.code && (defaultInfo.code = '1')
    return axios.post('/api/login', {...defaultInfo})
}

export const changeCode = (data) => {
    return axios.post('/api/code', data)
}

/**
 * @logout 登出接口
 * **/

export const logout = () => {
    return axios.post('/api/logout')
}

/**
 * 获取用户信息
 * **/
export const getHomeInfo = () => {
    return axios.get('/api/home/info')
}

/**
 * 获取列表信息
 * 
 * **/

 export const getHomeList = (info) => {
     const defaultInfo = {
         page: 1,
         pageSize: 10,
         ...info
     }
     return axios.get(`/api/home/list?page=${defaultInfo.page}&pageSize=${defaultInfo.pageSize}`)
 }

 /**
 * @deleteInfo 文件删除
 * @param id 文件版本
 * **/
export const deleteInfo = (id) => {
    return axios.delete(`/api/home/file/${id}`)
}

/**
 * @getProgress 获取包解析进度
 * **/

 export const getProgress = () => {
     return axios.get('/api/home/progress', false)
 }

 /**
  * @modifyPassword 修改密码
  * @param values {oldPassword, newPassword}
  * **/
 export const modifyPassword = (values) => {
    return axios.post('/api/home/password', {...values})
 }


//  后台页面相关================

/**
 * @getLogList 获取日志信息
 * @data 日志参数
 * @param page 当前页数
 * @param pageSize 当页展示个数
 * @param state 日志类型 1:操作日志，2:登录日志，3：升级日志
 * **/
 export const getLogList = (data) => {
    const defaultData = {
        page: 1,
        pageSize: 10,
        ...data
    }

    return axios.get(`/api/log/list?page=${defaultData.page}&pageSize=${defaultData.pageSize}&state=${defaultData.state}`)
 }

 /**
  * @deleteJournalInfo 删除某条日志
  * @param id 日志对应id
  * **/

 export const deleteJournalInfo = (id) => {
    return axios.delete(`/api/log/${id}`)
 }

  /**
  * @deleteBatchInfo 删除某类型7天前日志
  * @param state 日志日志类型 1:操作日志，2:登录日志，3：升级日志
  * **/
 export const deleteBatchInfo = (state) => {
     return axios.delete(`/api/log/batch/${state}`)
 }

 export const getUserList = (data) => {
    const defaultData = {
        page: 1,
        pageSize: 10,
        ...data
    }

    return axios.get(`/api/blank/user?page=${defaultData.page}&pageSize=${defaultData.pageSize}`)
 }

 export const getBlackList = (data) => {
    const defaultData = {
        page: 1,
        pageSize: 10,
        ...data
    }

    return axios.get(`/api/blank/list?page=${defaultData.page}&pageSize=${defaultData.pageSize}`)
 }

 
 export const deleteBlackInfo = (id) => {
    return axios.delete(`/api/blank/delete/${id}`)
 }

 export const addBlackInfo = (data) => {
     return axios.post('/api/blank/add', {...data})
 }

 export const updateBlackInfo = (data) => {
    return axios.post('/api/blank/update', {...data})
 }
 